.overlay-big {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.overlay-standard {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}

.image-card-wrapper {
  overscroll-behavior-x: contain !important;
  overflow-x: scroll;
}

.image-card-element {
  transition-property: background, box-shadow, border-color;
  transition-duration: 100ms;
  transition-timing-function: linear;

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 0.625rem 1.875rem;
    background-color: rgb(255, 255, 255);
  }
}

.imageCard .slick-track {
  display: flex;
  align-items: stretch;
}
.imageCard--big .slick-slide {
  width: 315px;
  height: 358px;
  padding-left: 10px;
  &:first-child {
    width: 325px;
    padding-left: 20px;
  }
}
.imageCard--small .slick-slide {
  width: 224px;
  height: 214px;
  padding-left: 10px;
  &:first-child {
    width: 234px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 768px) {
  .imageCard--big .slick-slide {
    width: 643px;
    height: 623px;
    padding-left: 20px;
    &:first-child {
      width: 703px;
      padding-left: 80px;
    }
  }
  .imageCard--small .slick-slide {
    width: 383px;
    height: 248px;
    padding-left: 20px;
    &:first-child {
      width: 443px;
      padding-left: 80px;
    }
  }
}